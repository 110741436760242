import { format } from 'date-fns';
import { NextRouter } from 'next/router';

import { COSGainType } from '@/components/pages/join/validationSchema';
import { JourneyVariantByPath } from '@/constants/marketing';
import { JoinFormValues } from '@/data/join';
import {
	GasSuppliers,
	TG_GAS_SUPPLIERS,
} from '@/domain/product/dual-fuel/constants';
import { featureFlags } from '@/features/featureFlags';
import apiClient from '@/services/api-client';
import graphqlClient from '@/services/graphql-client';
import {
	GenerationPointInput,
	InitiateMoveInInput,
	InitiateMoveInMutation,
	InitiateMoveOutAndMoveInInput,
	InitiateSwitchInInput,
	InitiateSwitchInMutation,
	PaymentTypeChoices,
} from '@/services/typed-graphql-sdk';
import { readSessionStorageOnce } from '@/utils/readSessionStorageOnce';

export const initiateMoveOutAndMoveInMutation = async ({
	values,
	router,
	analyticsCallback,
}: {
	analyticsCallback?: (params: object) => void;
	router: NextRouter;
	values: JoinFormValues;
}): Promise<void> => {
	const input = getInitiateMoveOutAndMoveInInput(values);
	const { initiateMoveOutAndMoveIn } =
		await graphqlClient.initiateMoveOutAndMoveIn({ input });
	switch (initiateMoveOutAndMoveIn.__typename) {
		case 'MoveOutAndMoveInInitiated': {
			const { accountNumber, token, hasUsablePassword } =
				initiateMoveOutAndMoveIn;
			if (analyticsCallback) {
				analyticsCallback({
					accountNumber,
					cosGainType: COSGainType.moveIn,
				});
			}

			router.push({
				pathname: '/join/set-user-password',
				query: {
					accountNumber,
					token,
					isMimo: 'true',
					hasUsablePassword,
					...router?.query,
				},
			});
			break;
		}
		case 'MoveInDateInvalid': {
			throw new Error(
				`Error: MoveInDateInvalid type in initiateMoveOutAndMoveIn mutation response. ${initiateMoveOutAndMoveIn.message}`
			);
		}
		case 'MoveOutDateInvalid': {
			throw new Error(
				`Error: MoveOutDateInvalid type in initiateMoveOutAndMoveIn mutation response. ${initiateMoveOutAndMoveIn.message}`
			);
		}
		case 'CommonError': {
			throw new Error(
				`Error: CommonError type in initiateMoveOutAndMoveIn mutation response. ${initiateMoveOutAndMoveIn.message}`
			);
		}
	}
};

export const initiateMoveInMutation = async ({
	values,
	router,
	analyticsCallback,
}: {
	analyticsCallback?: (params: object) => void;
	router: NextRouter;
	values: JoinFormValues;
}): Promise<void> => {
	const input = getInitiateMoveInMutationInput(values);
	const { initiateMoveIn }: InitiateMoveInMutation = await apiClient.post(
		'/api/onboarding/initiate-move-in',
		{ input }
	);
	switch (initiateMoveIn.__typename) {
		case 'MoveInSuccess': {
			const { accountNumber, userId, token, hasUsablePassword } =
				initiateMoveIn;
			router.push({
				pathname: '/join/set-user-password',
				query: {
					accountNumber,
					userId,
					token,
					hasUsablePassword,
					type: 'move-in',
				},
			});
			if (analyticsCallback) {
				analyticsCallback({
					accountNumber: initiateMoveIn.accountNumber,
					cosGainType: COSGainType.moveIn,
				});
			}

			break;
		}
		case 'CommonError': {
			throw new Error(
				`Error: CommonError type in inititateMoveIn mutation response. ${initiateMoveIn.message}`
			);
		}
	}
};

export const initiateSwitchInMutation = async ({
	values,
	router,
	analyticsCallback,
}: {
	analyticsCallback?: (params: object) => void;
	router: NextRouter;
	values: JoinFormValues;
}): Promise<void> => {
	const input = getInitiateSwitchInInput(values);
	const { initiateSwitchIn }: InitiateSwitchInMutation = await apiClient.post(
		'/api/onboarding/initiate-switch-in',
		{ input }
	);

	if (analyticsCallback) {
		analyticsCallback({
			accountNumber: initiateSwitchIn?.accountNumber,
			cosGainType: COSGainType.switchIn,
		});
	}

	router.push({
		pathname: '/join/set-user-password',
		query: {
			accountNumber: initiateSwitchIn?.accountNumber,
			userId: initiateSwitchIn?.userId,
			token: initiateSwitchIn?.token,
			hasUsablePassword: initiateSwitchIn?.hasUsablePassword,
			type: 'switch-in',
		},
	});
};

export const getInitiateMoveOutAndMoveInInput = (
	values: JoinFormValues
): InitiateMoveOutAndMoveInInput => {
	return {
		moveOut: {
			accountNumber: values.moveOutAccountId,
			moveOutDate: values.moveOutDate,
			propertyId: values.moveOutPropertyId,
		},
		syncMoveIn: {
			quoteCode: values.quote.code,
			productCode: values.productCode,
			moveInDate: values.moveInDate.replace(/\//g, '-'),
			property: {
				addressLine1: `${values.postalArea.prefecture}${values.postalArea.city}${values.postalArea.area}${values.addressLine1}`,
				addressLine2:
					values.propertyType === 'apartment'
						? `${values.buildingName} ${values.buildingNumber} ${values.roomNumber}`
						: '',
				postcode: values.postcode,
			},
			referralSchemeCode: values.referralSchemeCode,
			...getMoveOutAndMoveInBillingInputs(values),
		},
	};
};

export const getInitiateMoveInMutationInput = (
	values: JoinFormValues
): InitiateMoveInInput => {
	return {
		moveInDate: values.moveInDate.replace(/\//g, '-'),
		customer: {
			familyName: values.lastName,
			givenName: values.firstName,
			familyNameKana: values.lastNameKatakana,
			givenNameKana: values.firstNameKatakana,
			email: values.email,
			mobile: values.mobile,
			marketingCommunicationsOptIn: values.marketingCommunicationsOpt === 'in',
		},
		property: {
			addressLine1: `${values.postalArea.prefecture}${values.postalArea.city}${values.postalArea.area}${values.addressLine1}`,
			addressLine2:
				values.propertyType === 'apartment'
					? `${values.buildingName} ${values.buildingNumber} ${values.roomNumber}`
					: '',
			postcode: values.postcode,
		},
		...getPaymentInputs(values),
		...getCommonInputs(values),
	};
};

export const getInitiateSwitchInInput = (
	values: JoinFormValues
): InitiateSwitchInInput => {
	return {
		customer: {
			familyName: values.lastName,
			givenName: values.firstName,
			familyNameKana: values.lastNameKatakana,
			givenNameKana: values.firstNameKatakana,
			email: values.email,
			mobile: values.mobile,
			marketingCommunicationsOptIn: values.marketingCommunicationsOpt === 'in',
			contractFamilyName: values.contractFamilyName,
			contractGivenName: values.contractGivenName,
			contractFamilyNameKana: values.contractFamilyNameKana,
			contractGivenNameKana: values.contractGivenNameKana,
			contractNumber: values.currentSupplierContractNumber,
		},
		property: {
			addressLine1: `${values.postalArea.prefecture}${values.postalArea.city}${values.postalArea.area}${values.addressLine1}`,
			addressLine2:
				values.propertyType === 'apartment'
					? `${values.buildingName} ${values.buildingNumber} ${values.roomNumber}`
					: '',
			postcode: values.postcode,
			...(values.SPIN && {
				supplyPointId: values.SPIN?.replace(/-/g, ''),
			}),
			currentSupplierCode: values.currentRetailer,
		},
		...getPaymentInputs(values),
		...getCommonInputs(values),
	};
};

const getPaymentInputs = (values: JoinFormValues) => {
	const [paymentTokenKey, paymentToken] =
		values.paymentType === PaymentTypeChoices.CreditCard
			? ['creditCardToken', values.creditCardToken]
			: values.paymentType === PaymentTypeChoices.BankAccount
				? ['bankAccountToken', values.bankAccountToken]
				: [];
	return {
		payment: {
			paymentType: values.paymentType || PaymentTypeChoices.Konbini,
			...(paymentTokenKey && { [paymentTokenKey]: paymentToken }),
			...(values.paymentType === PaymentTypeChoices.BankAccount
				? {
						accountNumber: values.bankAccountNumber,
						accountHolder: values.bankAccountHolderName,
					}
				: {}),
		},
		billing: {
			billingAddressLine1: `${values.billingPostalArea.prefecture}${values.billingPostalArea.city}${values.billingPostalArea.area}${values.billingAddressLine1}`,
			billingAddressLine2:
				values.billingPropertyType === 'apartment'
					? `${values.billingBuildingName}${values.billingBuildingNumber}${values.billingRoomNumber}`
					: '',
			billingPostcode: values.billingPostcode,
		},
	};
};

const getMoveOutAndMoveInBillingInputs = (values: JoinFormValues) => {
	return {
		billing: {
			billingAddressLine1: `${values.billingPostalArea.prefecture}${values.billingPostalArea.city}${values.billingPostalArea.area}${values.billingAddressLine1}`,
			billingAddressLine2:
				values.billingPropertyType === 'apartment'
					? `${values.billingBuildingName}${values.billingBuildingNumber}${values.billingRoomNumber}`
					: '',
			billingPostcode: values.billingPostcode,
		},
	};
};

const getCommonInputs = (values: JoinFormValues) => {
	return {
		quoteCode: values.quote.code,
		productCode: values.productCode,
		...(values.affiliateLinkSubdomain
			? { affiliateLinkSubdomain: values.affiliateLinkSubdomain }
			: {}),
		...(values.referralCode ? { referralCode: values.referralCode } : {}),
		...(values.referralSchemeCode
			? { referralSchemeCode: values.referralSchemeCode }
			: {}),
		...getRewardCodeInput(),
		...getGenerationDataInput(values),
	};
};
/**
 * Determine the reward or affiliate code input for the InitiateSwitchInMutation.
 *
 * Return one or many of the following:
 * - An affiliate code and (optionally) audio recording id.
 * - A valid referral code. (friend referral)
 * - A referral scheme code. (any ReferralScheme code)
 */

export const getRewardCodeInput = (): Pick<
	InitiateSwitchInInput,
	| 'affiliateLinkSubdomain'
	| 'audioRecordingId'
	| 'referralCode'
	| 'referralSchemeCode'
> => {
	const referralCode = readSessionStorageOnce<string>('referralCode');
	const referralSchemeCode =
		readSessionStorageOnce<string>('referralSchemeCode');
	const affiliateCode = readSessionStorageOnce<string>('affiliateCode');
	const audioRecordingId = readSessionStorageOnce<string>('audioRecordingId');

	/*
	 * referralCode and referralSchemeCode can not be both included because it
	 * will apply both rewards to the account, which isn't allowed.
	 * In case both are present, use referralCode.
	 */
	const resolveReferralRewardConflict = (
		referralCode: string | null,
		referralSchemeCode: string | null
	) => {
		if (referralSchemeCode && !referralCode) {
			return { referralSchemeCode };
		} else if (referralCode) {
			return { referralCode };
		} else {
			return {};
		}
	};

	return {
		...(affiliateCode && !referralCode
			? {
					affiliateLinkSubdomain: affiliateCode,
					...(audioRecordingId ? { audioRecordingId } : {}),
				}
			: {}),
		...resolveReferralRewardConflict(referralCode, referralSchemeCode),
	};
};

export const getGenerationDataInput = (
	updatedValues: JoinFormValues
): { generationPoint: GenerationPointInput } | Record<string, unknown> => {
	const isFiTJourney =
		readSessionStorageOnce('user-journey') === JourneyVariantByPath.fit;

	return isFiTJourney
		? {
				generationPoint: {
					customer: {
						contractFamilyName: updatedValues.lastName,
						contractFamilyNameKana: updatedValues.lastNameKatakana,
						contractGivenName: updatedValues.firstName,
						contractGivenNameKana: updatedValues.firstNameKatakana,
						contractNumber: updatedValues.currentGenerationContractNumber,
						email: updatedValues.email,
						familyName: updatedValues.lastName,
						familyNameKana: updatedValues.lastNameKatakana,
						givenName: updatedValues.firstName,
						givenNameKana: updatedValues.firstNameKatakana,
						marketingCommunicationsOptIn:
							updatedValues.marketingCommunicationsOpt === 'in',
						mobile: updatedValues.mobile,
					},
					generationPointId: updatedValues.GPIN.replace(/-/g, ''),
					currentSupplierCode: updatedValues.currentGenerationPurchasor,
					productCode: updatedValues.quotedGenerationProduct.product.code,
				} as GenerationPointInput,
			}
		: {};
};

/**
 * @note
 * Replace if/when exposed in Kraken gql types for InitiateSwitchIn mutation
 * (account note input)
 */
type DualFuelAccountNoteSchema = {
	gas_supply_period: {
		gas_application_customer_family_name_kana?: string;
		gas_application_customer_family_name_kanji?: string;
		gas_application_customer_given_name_kana?: string;
		gas_application_customer_given_name_kanji?: string;
		gas_application_customer_mobile?: string;
		gas_application_supply_area?: string;
		gas_application_supply_building_name?: string;
		gas_application_supply_building_number?: string;
		gas_application_supply_city?: string;
		gas_application_supply_lot_number?: string;
		gas_application_supply_postcode?: string;
		gas_application_supply_prefecture?: string;
		gas_application_supply_room_number?: string;
		gas_move_in_visit_attendant_family_name_kanji?: string;
		gas_move_in_visit_attendant_given_name_kanji?: string;
		gas_move_in_visit_attendant_mobile?: string;
		gas_move_in_visit_attendant_selection?: string;
		gas_move_in_visit_date?: string;
		gas_move_in_visit_time?: string;
		gas_switch_in_current_menu?: string;
		gas_switch_in_current_supplier?: string;
		gas_switch_in_other_contract_number?: string;
		gas_switch_in_spin?: string;
		gas_switch_in_tokyo_gas_customer_number?: string;
	};
};

/**
 * Formats the accountNote input string
 * - No keys with empty string '' or null values
 * - Human readable JSON with 2 spaces and newlines
 */
export const getDualFuelAccountNoteInput = (
	values: JoinFormValues
): Pick<InitiateSwitchInInput, 'accountNote'> => {
	if (
		values.isDualFuelProductSelected &&
		featureFlags.NEXT_PUBLIC_FEATURE_DUAL_FUEL_OBJ
	) {
		const { gas_supply_period } = getDualFuelAccountNoteSchema(values);
		const prunedNullOrEmptyStringEntries = Object.fromEntries(
			Object.entries(gas_supply_period).filter(([, value]) => !!value)
		);
		return {
			accountNote: JSON.stringify(
				{ gas_supply_period: prunedNullOrEmptyStringEntries },
				null,
				2
			),
		};
	} else {
		return {};
	}
};

export const getDualFuelAccountNoteSchema = ({
	propertyType,
	addressLine1,
	buildingName,
	buildingNumber,
	COSGainType,
	roomNumber,
	lastName,
	firstName,
	lastNameKatakana,
	firstNameKatakana,
	mobile,
	postalArea,
	gasSwitchCurrentSupplier,
	gasSwitchCurrentSupplierName,
	gasSwitchTokyoGasCustomerNumber,
	gasSwitchCurrentMenu,
	gasSwitchSPIN,
	gasSwitchOtherContractNumber,
	gasMoveVisitDate,
	gasMoveVisitTime,
	gasMoveVisitAttendantLastNameKanji,
	gasMoveVisitAttendantFirstNameKanji,
	gasMoveVisitAttendantSelection,
	gasMoveVisitAttendantMobile,
}: JoinFormValues): DualFuelAccountNoteSchema => {
	const customerFields = {
		gas_application_customer_family_name_kana: lastNameKatakana,
		gas_application_customer_family_name_kanji: lastName,
		gas_application_customer_given_name_kana: firstNameKatakana,
		gas_application_customer_given_name_kanji: firstName,
		gas_application_customer_mobile: mobile,
		gas_application_supply_postcode: postalArea?.postcode,
		gas_application_supply_prefecture: postalArea?.prefecture,
		gas_application_supply_city: postalArea?.city,
		gas_application_supply_area: postalArea?.area,
		gas_application_supply_lot_number: addressLine1,
		...(propertyType === 'apartment'
			? {
					gas_application_supply_building_name: buildingName,
					gas_application_supply_building_number: buildingNumber,
					gas_application_supply_room_number: roomNumber,
				}
			: {}),
	};
	const cosGainFields =
		COSGainType === 'switchIn'
			? {
					gas_switch_in_current_menu: gasSwitchCurrentMenu?.value,
					gas_switch_in_current_supplier:
						gasSwitchCurrentSupplier?.value === GasSuppliers.other
							? gasSwitchCurrentSupplierName
							: gasSwitchCurrentSupplier?.value,
					...(TG_GAS_SUPPLIERS.some(
						(supplier) => supplier === gasSwitchCurrentSupplier?.value
					)
						? {
								gas_switch_in_tokyo_gas_customer_number:
									gasSwitchTokyoGasCustomerNumber,
							}
						: {
								gas_switch_in_other_contract_number:
									gasSwitchOtherContractNumber,
								gas_switch_in_spin: gasSwitchSPIN,
							}),
				}
			: {
					gas_move_in_visit_attendant_selection:
						gasMoveVisitAttendantSelection?.value,
					...(gasMoveVisitAttendantSelection?.value === '1'
						? {
								gas_move_in_visit_attendant_mobile: mobile,
								gas_move_in_visit_attendant_family_name_kanji: lastName,
								gas_move_in_visit_attendant_given_name_kanji: firstName,
							}
						: {
								gas_move_in_visit_attendant_mobile: gasMoveVisitAttendantMobile,
								gas_move_in_visit_attendant_family_name_kanji:
									gasMoveVisitAttendantLastNameKanji,
								gas_move_in_visit_attendant_given_name_kanji:
									gasMoveVisitAttendantFirstNameKanji,
							}),
					gas_move_in_visit_date: format(
						new Date(gasMoveVisitDate),
						'yyyy-MM-dd'
					),
					gas_move_in_visit_time: gasMoveVisitTime?.value,
				};
	return {
		gas_supply_period: {
			...customerFields,
			...cosGainFields,
		},
	};
};

import {
	GridOperatorCode,
	gridOperatorCodeFromPostalArea,
	PrefectureName,
	SUPPORTED_SIMPLE_PREFECTURE_NAMES,
} from '@/constants/industry/gridOperator';
import {
	JourneyVariantByPath,
	getShouldDisplaySimpleOrganisationNames,
} from '@/constants/marketing';
import { getRewardCodeInput } from '@/queriesAndMutations/join';
import apiClient from '@/services/api-client';
import graphqlClient from '@/services/graphql-client';
import {
	Amperage,
	CreateElectricityQuoteMutation,
	PostalAreaFragment,
	QuoteRequestQuery,
} from '@/services/typed-graphql-sdk';
import { readSessionStorageOnce } from '@/utils/readSessionStorageOnce';

export const createAndGetQuote = async (input: {
	amperage?: Amperage;
	gridOperatorCodeFromSPIN?: GridOperatorCode;
	kva?: number;
	postalArea: PostalAreaFragment;
	productParams: ProductFilterParamType;
	usageAmount: number;
	usageMonth: number;
	useCache?: boolean;
}): Promise<QuoteRequestQuery['quoteRequest']> => {
	const affiliateSessionId =
		readSessionStorageOnce<string>('affiliateSessionId');
	const requestApi = input.useCache
		? '/api/onboarding/cached-create-quote'
		: '/api/onboarding/create-quote';
	const { createElectricityQuote } =
		await apiClient.post<CreateElectricityQuoteMutation>(requestApi, {
			input: {
				...(affiliateSessionId ? { affiliateSessionId } : {}),
				...(input.amperage ? { amperage: input.amperage } : {}),
				...(input.kva ? { kva: input.kva } : {}),
				gridOperatorCode:
					input.gridOperatorCodeFromSPIN ||
					gridOperatorCodeFromPostalArea(
						input.postalArea as PostalAreaFragment
					),
				params: getRewardCodeInput(),
				productParams: input.productParams,
				postalArea: input.postalArea,
				usageMonth: input.usageMonth,
				usageAmount: input.usageAmount,
			},
		});
	if (!createElectricityQuote?.quoteRequest?.code) {
		throw new Error('No quote returned from CreateElectricityQuoteMutation');
	}

	const { quoteRequest } = await graphqlClient.quoteRequest({
		code: createElectricityQuote.quoteRequest.code,
	});

	return quoteRequest as QuoteRequestQuery['quoteRequest'];
};

/**
 * Product param keys that can be filtered by True/False values.
 * "-" prefix performs a filter on False values.
 */
export const ProductFilterParams = [
	'is_all_denka',
	'-is_all_denka',
	'is_ev',
	'-is_ev',
	'is_fit',
	'-is_fit',
	'is_green',
	'-is_green',
	'is_solar',
	'-is_solar',
	'is_standard',
	'-is_standard',
	'is_fixed_fca',
	'-is_fixed_fca',
	'is_simple',
	'-is_simple',
] as const;

export type ProductFilterParamType = (typeof ProductFilterParams)[number][];

// By default, filter these products in the quote request
export const DefaultExcludeProductParams: ProductFilterParamType = [
	'-is_all_denka',
	'-is_simple',
	'-is_ev',
	'-is_solar',
];

// To quote All Electric, Simple, and Green exclusively in MOMI by excluding ev, solar, and standard
export const ProductParamsAllDenkaSimpleAndGreen: ProductFilterParamType = [
	'-is_ev',
	'-is_solar',
	'-is_standard',
];

// To quote All Electric and Green exclusively in MOMI by excluding ev, solar, simple, and standard when outside of Simple Octopus region
export const ProductParamsAllDenkaAndGreen: ProductFilterParamType = [
	'-is_ev',
	'-is_solar',
	'-is_standard',
	'-is_simple',
];

// To quote All Electric Octopus Products exclusively
export const ProductParamsAllElectricOctopus: ProductFilterParamType = [
	'is_all_denka',
];

// To quote Green Octopus and Simple Octopus only
export const ProductParamsSimpleAndGreenOctopus: ProductFilterParamType = [
	'-is_all_denka',
	'-is_standard',
	'-is_ev',
	'-is_solar',
];

// To quote Green Octopus Products exclusively
export const ProductParamsGreenOctopus: ProductFilterParamType = [
	'is_green',
	'-is_ev',
	'-is_solar',
	'-is_all_denka',
];

// To quote EV Octopus Products exclusively
export const ProductParamsEvOctopus: ProductFilterParamType = ['is_ev']; // To quote Solar Octopus Products exclusively

// To quote Solar Octopus Products exclusively
export const ProductParamsSolarOctopus: ProductFilterParamType = ['is_solar'];

export const getQuoteProductParamsInput = ({
	isSimpleOctopusRegion,
}: {
	isSimpleOctopusRegion?: boolean;
}): Partial<{
	productParams: ProductFilterParamType;
}> => {
	const userJourney = readSessionStorageOnce('user-journey');
	const isAllElectricJourney = userJourney === JourneyVariantByPath.allElectric;
	const isFiTJourney = userJourney === JourneyVariantByPath.fit;
	const isEvJourney = userJourney === JourneyVariantByPath.ev;
	const isSolarJourney = userJourney === JourneyVariantByPath.solar;

	const affiliateSalesChannel = readSessionStorageOnce('affiliateSalesChannel');
	const isPriceComparisonChannel = affiliateSalesChannel === 'PRICE_COMPARISON';
	const isFieldSalesChannel = affiliateSalesChannel === 'FIELD_SALES';

	const affiliateOrganisationName = readSessionStorageOnce<string>(
		'affiliateOrganisationName'
	);

	const isValidAffiliateOrganization =
		!affiliateOrganisationName ||
		getShouldDisplaySimpleOrganisationNames().includes(
			affiliateOrganisationName
		);

	const isNotAffiliate = !isPriceComparisonChannel && !isFieldSalesChannel;

	const isSimpleOctopus =
		isSimpleOctopusRegion && (isValidAffiliateOrganization || isNotAffiliate);

	if (isAllElectricJourney) {
		return { productParams: ProductParamsAllElectricOctopus };
	} else if (isFiTJourney) {
		return { productParams: ProductParamsGreenOctopus };
	} else if (isEvJourney) {
		return { productParams: ProductParamsEvOctopus };
	} else if (isSolarJourney) {
		return { productParams: ProductParamsSolarOctopus };
	} else if (isSimpleOctopus) {
		return { productParams: ProductParamsSimpleAndGreenOctopus };
	} else {
		return { productParams: DefaultExcludeProductParams };
	}
};

// @fixme - Earl
// used on the /shortcut/tariff step
// this should be the amount selected from the homepage quick-quote
export const DefaultUsageAmount = 300;

// Always the current month as an integer
export const DefaultUsageMonth = new Date().getMonth() + 1;

/** @todo Update join machine with this as well in future PR */
export const getIsSimpleOctopusRegion = (prefectureName?: string): boolean =>
	SUPPORTED_SIMPLE_PREFECTURE_NAMES.includes(
		(prefectureName as PrefectureName) ?? ''
	);
